
@use "library/fonts-declarations"
@use "library/line-clamp"
@use "common/navigation"
@use "common/footer"

/* ------------------------------------------------------------------------
/* With box-sizing: border-box,
/*  - The padding and border are included in the element's specified width.
/*  - The element stays the exact width you set
/*  - Padding and borders grow inward instead of outward
/* ----------------------------------------------------------------
/* text-size-adjust: none
/*  - Prevents browsers from automatically adjusting text size
/*  - Particularly useful on mobile to stop iOS from enlarging text
/*  - Maintains consistent text size across devices

*
    box-sizing: border-box
    text-size-adjust: none
