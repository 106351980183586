/*---------------------------------------------*/
/* Fonts initialisation & mixin recipes
/*---------------------------------------------*/

@use "variables";
@use "text";

/*!
	---------------------------------------------
    VERLAG
	Copyright (C) 2014 Hoefler & Frere-Jones

	This software is the property of Hoefler & Frere-Jones (H&FJ).
	Your right to access and use this software is subject to the
	applicable License Agreement, or Terms of Service, that exists
	between you and H&FJ. If no such agreement exists, you may not
	access or use this software for any purpose.

	This software may only be hosted at the locations specified in
	the applicable License Agreement or Terms of Service, and only
	for the purposes expressly set forth therein.  You may not copy,
	modify, convert, create derivative works from or distribute this
	software in any way, or make it accessible to any third party,
	without first obtaining the written permission of H&FJ.

	For more information, please visit H&FJ at http://typography.com.
	166385-70526-20140131
	---------------------------------------------
	*/

 /*!
    -------------------------------------------------
      Roboto
      Font from Google Fonts - https://fonts.google.com/
      Font licensed under SIL Open Font License - https://scripts.sil.org/OFL
    ---------------------------------------------
 */

@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-100-Thin-Normal', 100);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-200-ExtraLight-Normal', 200);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-300-Light-Normal', 300);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-400-Regular-Normal', 400);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-500-Medium-Normal', 500);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-600-SemiBold-Normal', 600);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-700-Bold-Normal', 700);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-800-ExtraBold-Normal', 800);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-900-Black-Normal', 900);

@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-100-Thin-Italic', 100, italic);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-200-ExtraLight-Italic', 200, italic);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-300-Light-Italic', 300, italic);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-400-Regular-Italic', 400, italic);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-500-Medium-Italic', 500, italic);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-600-SemiBold-Italic', 600, italic);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-700-Bold-Italic', 700, italic);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-800-ExtraBold-Italic', 800, italic);
@include text.declare-font-face-min('Roboto Serif', 'robotoSerif', 'RobotoSerif-900-Black-Italic', 900, italic);

@include text.declare-font-face-min('Roboto Sans', 'robotoSans', 'RobotoSans-100-Thin-Normal', 100);
@include text.declare-font-face-min('Roboto Sans', 'robotoSans', 'RobotoSans-300-Light-Normal', 300);
@include text.declare-font-face-min('Roboto Sans', 'robotoSans', 'RobotoSans-400-Regular-Normal', 400);
@include text.declare-font-face-min('Roboto Sans', 'robotoSans', 'RobotoSans-500-Medium-Normal', 500);
@include text.declare-font-face-min('Roboto Sans', 'robotoSans', 'RobotoSans-700-Bold-Normal', 700);
@include text.declare-font-face-min('Roboto Sans', 'robotoSans', 'RobotoSans-900-Black-Normal', 900);

@include text.declare-font-face-min('Roboto Sans', 'robotoSans', 'RobotoSans-100-Thin-Italic', 100, italic);
@include text.declare-font-face-min('Roboto Sans', 'robotoSans', 'RobotoSans-300-Light-Italic', 300, italic);
@include text.declare-font-face-min('Roboto Sans', 'robotoSans', 'RobotoSans-400-Regular-Italic', 400, italic);
@include text.declare-font-face-min('Roboto Sans', 'robotoSans', 'RobotoSans-500-Medium-Italic', 500, italic);
@include text.declare-font-face-min('Roboto Sans', 'robotoSans', 'RobotoSans-700-Bold-Italic', 700, italic);
@include text.declare-font-face-min('Roboto Sans', 'robotoSans', 'RobotoSans-900-Black-Italic', 900, italic);

/*---------------------------------------------*/
/* Verlag
/* Police de l'article - Rédactionnel générique (titres)
/*---------------------------------------------*/

@include text.declare-font-face-min('Verlag', 'verlag', 'Verlag-Light', 300);
@include text.declare-font-face-min('Verlag', 'verlag', 'Verlag-XLight', 100);
@include text.declare-font-face-min('Verlag', 'verlag', 'Verlag-Book', 500);
@include text.declare-font-face-min('Verlag', 'verlag', 'Verlag-Bold', 700);
@include text.declare-font-face-min('Verlag', 'verlag', 'Verlag-Black', 900);

/*---------------------------------------------*/
/* Calluna
/* Police de l'article - Rédactionnel générique (corps)
/*---------------------------------------------*/

@include text.declare-font-face-min('Calluna','calluna','Calluna-Regular');
@include text.declare-font-face-min('Calluna','calluna','Calluna-Light', 300);
@include text.declare-font-face-min('Calluna','calluna','Calluna-Bold', bold);
@include text.declare-font-face-min('Calluna','calluna','Calluna-Semibold', 600);
@include text.declare-font-face-min('Calluna','calluna','Calluna-Black', 900);

/*---------------------------------------------*/
/* Rubrik
/* Police de la coquille
/*---------------------------------------------*/
@include text.declare-font-face-min('rubrik', 'rubrik', 'Rubrik-ExtraLight', 200);
@include text.declare-font-face-min('rubrik', 'rubrik', 'Rubrik-Light', 300);
@include text.declare-font-face-min('rubrik', 'rubrik', 'Rubrik-Regular', normal);
@include text.declare-font-face-min('rubrik', 'rubrik', 'Rubrik-Bold', bold);
@include text.declare-font-face-min('rubrik', 'rubrik', 'Rubrik-Medium', 500);
@include text.declare-font-face-min('rubrik', 'rubrik', 'Rubrik-SemiBold', 600);


/*---------------------------------------------*/
/* Sentinel
/* Police des articles de la section Débat
/*---------------------------------------------*/

@include text.declare-font-face-min('Sentinel','sentinel','Sentinel-Light',300);
@include text.declare-font-face-min('Sentinel','sentinel','Sentinel-Book');
@include text.declare-font-face-min('Sentinel','sentinel','Sentinel-Bold',bold);
@include text.declare-font-face-min('Sentinel','sentinel','Sentinel-Medium',500);
@include text.declare-font-face-min('Sentinel','sentinel','Sentinel-Semibold',600);
@include text.declare-font-face-min('Sentinel','sentinel','Sentinel-Black', 900);
//charger l'italique
@include text.declare-font-face-min('Sentinel','sentinel','Sentinel-LightItalic',300,italic);
@include text.declare-font-face-min('Sentinel','sentinel','Sentinel-BookItalic',normal,italic);
@include text.declare-font-face-min('Sentinel','sentinel','Sentinel-BoldItalic',bold,italic);
@include text.declare-font-face-min('Sentinel','sentinel','Sentinel-MediumItalic',500,italic);
@include text.declare-font-face-min('Sentinel','sentinel','Sentinel-SemiboldItalic',600,italic);
@include text.declare-font-face-min('Sentinel','sentinel','Sentinel-BlackItalic', 900,italic);
