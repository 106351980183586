@use "../library/breakpoints"
@use "../library/colors"
@use "userAccountWidget"
@use "include-media/dist/include-media"
@use "../library/fonts"
@use "../library/variables"

=SelectedMenuItemBorder
    @each $name, $color in colors.$navBorder
        &.#{$name}.selected  .burger__sections__item:before
            background: $color

=SelectedMenuItemBg
    @each $name, $color in colors.$navBkg
        &.#{$name}.selected .burger__sections__item
            background-color: #{$color}
            border-top: 1px solid #{$color}

.burger
    display: none

@include include-media.media("<min")
    .burger
        display: block

    .burgerSearch
        border: solid 1px #4a4a4a
        margin: 0 1rem 1rem 1rem
        height: 43px
        padding: 11px 1em 1em


    .burgerSearch__form
        display: flex
        flex-direction: row
        gap: 1em


    .burgerSearch__label
        width: 100%

    .burgerSearch__query
        background-color: transparent
        border: 0
        color: #fff
        font-family: "rubrik", Arial, "Helvetica Neue", Helvetica, sans-serif
        outline: none
        width: 100%
        font-size: 0.938rem
        padding-top: 0

    .burgerSearch__query::placeholder
        color: #adadad

    .burgerSearch__submit
        background-color: transparent
        border: 0
        background-size: auto 17px
        background-position: center center
        background-repeat: no-repeat
        background-image: url("assets/recherche-grey.inline.svg")
        cursor: pointer
        height: 18px
        width: 18px


    .burger__sections > li.selected .burger__sections__item:before
        display: inline-block
        content: " "
        width: 0.5rem
        height: calc(2.4rem + 1px)
        position: absolute
        top: -1px
        left: 0

    .burger__sections > li.SER.selected
        .burger__sections__item
            &:before
                background: colors.$XTR
            background-color: colors.$XTR-3
            border-top: 1px solid colors.$XTR-3

    .mainNav__sectionLink--SER
        .burger__sections__item
            color: #9e9e9e

    .burger
        width: 100%
        height: 44px
        clear: both
        background: #ed1c23
        overflow: visible
        border-bottom-width: 8px
        font-family: fonts.$fontVerlag
        font-weight: 500
        font-size: 0.75rem

        position: fixed
        top: 0
        z-index: 1000

        a, span, h1
            display: inline-block

        &__logo
            width: calc(100% - 103px)
            text-align: center
            font-size: 1.5em
            font-weight: 800
            padding-top: 13px
            letter-spacing: -0.03rem
            text-transform: uppercase
            & a
                text-decoration: inherit
                color: white
        div.burger__logo
            display: inline-block
            padding-top: 11px

        &__closePane
            z-index: 9
            display: block
            right: 100%
            top: 44px
            width: 100%
            height: calc(100vh - 44px)
            background-color: black
            opacity: 0
            transition: opacity .3s ease
            position: fixed

        &__showMenu
            display: inline-block
            background: url(variables.$img-path + "/menu-icon.png") 0 0 no-repeat
            .burgerNavOpen &
                display: none

        &__hideMenu
            display: none
            .burgerNavOpen &
                display: inline-block
                background: url(variables.$img-path + "/close-icon.png") 0 0 no-repeat

        &__showMenu,
        &__hideMenu
            background-size: 100%
            width: 25px
            height: 20px
            margin: 12px 15px
            vertical-align: top
            border: none
            padding: 0
            .burgerNavOpen &
                background-size: 100%
                width: 22px
                height: 21.5px

        &__menu
            z-index: 10
            display: inline-block
            position: relative
            height: 100vh
            width: 80%
            background: #1d1d1d
            transition: margin-left .3s ease
            vertical-align: top
            margin-left: -81%
            overflow: scroll
            overflow-x: hidden
            padding-bottom: 150px

            &__top
                width: 100%

            &__logo
                height: 4.125rem
                margin: 1rem 0.75rem
                padding: 0
                vertical-align: top
                img
                    height: 100%
                    aspect-ratio: 1
                    object-fit: cover
            &__contribution
                display: inline-block
                width: auto
                height: 46px
                vertical-align: top
                margin-top: 1rem
                line-height: 1.8rem
                padding: 0.5rem 1.385rem
                margin-left: calc(100% - 300px)
                font-size: .875rem
                font-family: fonts.$fontRubrik
                font-weight: 500
                color: #222
                border-radius: 24px
                border: 1px solid #222
                background-color: #ffe501
                text-decoration: none
                @include include-media.media('<375px')
                    padding: 0.5rem 0.461rem
                    margin-left: calc(100% - 263px)
                &:focus
                    background-color: #ffe50190
                &:hover
                    border: solid 1px #323232
                    color: #323232
                    background-color: #fde831
                &:active
                    color: #646464
                    border: solid 1px #646464
                    background-color: #fcec63
        &__sections
            display: block
            height: auto
            margin: 0 0 125px 0
            padding: 0
            overflow: auto
            & > li
                list-style: none
                cursor: pointer
                display: block
                position: relative
                border-top: 1px solid #4a4a4a
                &:last-child
                    border-bottom: 1px solid #4a4a4a
                +SelectedMenuItemBg
                +SelectedMenuItemBorder
                &.selected
                    border-top: 0

            &__item
                display: block
                position: relative
                text-transform: uppercase
                color: #fff
                text-decoration: none
                padding: 0 1rem
                letter-spacing: 0.9px
                line-height: 2.4rem
                width: 100%
                &:after
                    position: absolute
                    font-size: 1.4rem
                    content: ">"
                    right: 2.25rem
                    font-weight: 100
                    transform: rotate(90deg)
        &__subSections
            display: block
            transition: height 1s ease-in-out
            height: 0
            max-height: 0
            margin: 0
            padding: 0
            background-color: #353535
            overflow: hidden

            & > li
                cursor: pointer
                display: block
                position: relative
                font-family: fonts.$fontRubrik
                font-weight: 300
                a
                    color: #fff
                    text-decoration: none
                    letter-spacing: 0.4px
                    font-size: 0.8125rem
                    line-height: 1
                    padding: 0.9rem 1rem
                    width: 100%
                    &:focus
                        text-decoration: underline
            & > li.selected
                a
                    text-decoration: underline

        &__userAccount
            display: inline-block
            position: relative
            top: 5px
            right: -10px
            width: 22px
            height: 22px

            &--state-hidden
                display: none

            &--state-open .userAccountWidget__popin
                display: block

    .selected .burger__sections__item
        &:after
            transform: rotate(-90deg)
            transition: all .3s ease-in-out
        + .burger__subSections
            height: auto
            max-height: 800px
            transition: max-height 1s ease-in-out

    .burgerNavOpen
        transition: all 1s ease-in-out
        .burger
            &__logo
                display: none
            &__menu
                margin-left: 0
            &__closePane
                opacity: 0.5
                right: 0

    body.burgerNavOpen
        position: fixed
        height: 100vh
        overflow: hidden

    .burgerFooter
        padding-left: 1rem
        margin-top: -100px
        &__text
            text-transform: uppercase
            font-family: Rubrik
            font-size: 12px
            font-weight: 600
            font-stretch: normal
            font-style: normal
            line-height: 1.83
            letter-spacing: normal
            color: white
            margin-bottom: 10px
            &--link
                text-decoration: none
                font-family: Rubrik
                font-size: 12px
                font-weight: 500
                font-stretch: normal
                font-style: normal
                line-height: 2.5
                letter-spacing: normal
                color: white
                padding-left: 11px
        &__container
            width: auto
            height: 40px
            margin-right: 15px
            border: solid 1px #4a4a4a
            background-color: #242424
            display: flex
            align-items: center
