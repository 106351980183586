@use "include-media/dist/include-media"
@use "../library/fonts"

.userAccountWidget
    width: 100%
    height: 100%
    font-size: 16px

    &__link
        width: 100%
        height: 100%
        display: inline-block
        background-size: auto 22px
        background-position: 50% center
        background-repeat: no-repeat
        background-image: url("assets/ic-profil.inline.svg")
        position: relative
        cursor: pointer

        &:before
            content: ""
            position: absolute
            width: 10px
            height: 10px
            background-color: #50b83d
            border-radius: 50%
            right: 0
            top: 0
            display: none

    &__popin
        display: none

    &__popin
        position: absolute
        background-color: black
        right: -7px
        top: 30px
        border: 1px solid white
        padding: 0 12px 3px 12px

        font-family: fonts.$fontRubrik
        font-size: 0.875rem
        line-height: 1
        color: white
        min-width: 226px

        &:after
            content: ""
            position: absolute
            top: -6px
            right: 12px
            width: 10px
            height: 10px
            border-top: 1px solid white
            border-left: 1px solid white
            background-color: black
            transform: rotate(45deg)

    &__title
        margin-top: 10px
        width: 100%
        text-align: center
        height: 25px
        font-family: fonts.$fontRubrik
        font-size: 14px
        font-weight: 500
        font-stretch: normal
        font-style: normal
        line-height: 1.79
        letter-spacing: 0.4px
        color: #fff

    &__blockUserNotVerified
        margin-top: 13px
        margin-left: 3px
        padding: 10px 14px 3px 12px
        width: 194px
        height: 140px
        text-align: left
        font-family: fonts.$fontRubrik
        font-size: 14px
        font-weight: 600
        font-stretch: normal
        font-style: normal
        line-height: 1.29
        letter-spacing: 0.4px
        color: #000000
        border: solid 1px #ffae00
        background-color: #fff5df

    &__clearBothUserNotVerified
        height: 10px

    &__textUserNotVerified
        font-family: fonts.$fontRubrik
        font-size: 12px
        font-weight: normal
        font-stretch: normal
        font-style: normal
        line-height: 1.33
        letter-spacing: normal
        color: #000000

    &__linkUserNotVerified
        font-family: fonts.$fontRubrik
        font-size: 12px
        font-weight: normal
        font-stretch: normal
        font-style: normal
        line-height: 1.33
        letter-spacing: normal
        color: #007aff

    &__email
        color: #acacac
        padding-top: 16px
        padding-left: 26px
        position: relative
        font-size: 0.875rem
        line-height: 1
        width: 200px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

        &:before
            content: ""
            position: absolute
            width: 10px
            height: 10px
            background-color: #50b83d
            border-radius: 50%
            left: 6px
            top: 19px

    &__btns
        margin-top: 12px
        padding: 0
        margin-bottom: 0
        list-style: none

        a, a:hover, a:visited
            width: 100%
            color: inherit
            text-decoration: none
            font-weight: 300

    &__btn
        background-color: #252525
        width: 100%
        height: 50px
        line-height: 50px
        cursor: pointer
        margin-bottom: 10px
        text-align: center
        border: 1px solid #414141

        &:hover
            background-color: #414141

    .userAccountWidget &__loggedIn
        display: none

    &__linkUserNotVerified--alreadyClicked
        display: none !important

    &__textUserNotVerified--notClicked
        display: none !important

    &--loggedIn &__link:before
        display: block

    &--loggedIn &__popin
        min-width: 226px

    &--loggedIn &__loggedIn
        display: inline-block

    &--loggedIn &__notLoggedIn
        display: none

    &--loggedIn &__title,
    .userAccountWidget &__title
        display: block

    &--notVerified &__link:before
        background-color: #ffae00

    &--notVerified &__title
        display: none

    &--loggedIn &__blockUserNotVerified
        display: none

    &--notVerified &__blockUserNotVerified
        display: block

    &--notVerified &__loggedIn:before
        background-color: #ffae00

@include include-media.media(">=min")
    .userAccountWidget
        width: 100%
        height: 100%
        font-size: 16px

        &__link:before
            right: 4px
            top: 8px

        &__popin
            right: 0
            top: 48px
            cursor: initial
