@use "socials"

@use "../library/breakpoints"
@use "include-media/dist/include-media"
@use "../library/fonts"

$footerHoverColor: #007aff

.footer
    background: #e6e6e6
    padding: 50px 20px 0 20px
    margin-top: 40px
    width: 100%
    clear: both

    ul
        list-style: none
        margin: 0
        padding: 0

    p
        margin: 0

        .copyright
            margin: 0

    @include include-media.media(">=min")
        padding: 50px 0 0
        margin-top: 80px

    & &__wrapper
        margin: auto
        color: #686868
        font-family: fonts.$fontRubrik

        font-size: 0.75rem
        line-height: 1

        @include include-media.media(">small")
            font-size: 0.875rem

        @include include-media.media(">=min")
            padding: 0 5px
            max-width: 1290px

        &:first-child
            justify-content: space-between
            display: flex
            flex-direction: column

            @include include-media.media(">=min")
                flex-direction: row
                padding: 0 15px

            @include include-media.media(">=full")
                padding: 0 5px

        &:last-child
            margin-top: 60px
            border-top: 1px solid #d4d4d4
            padding-top: 50px
            padding-right: 20px
            @include include-media.media(">min")
                padding: 50px 15px 0 15px
            @include include-media.media(">full")
                padding: 50px 5px 0 5px

            p
                margin-top: -30px

            & > .copyright
                float: left

            & > .legal
                display: none

                @include include-media.media(">=min")
                    display: block
                    float: right

                a:after
                    content: "|"
                    margin: 0 6px

                a:last-child:after
                    content: ""
                    margin: 0

                span
                    display: none

    & &__block, & &__block.applications
        flex-basis: initial
        margin-bottom: 28px

        @include include-media.media(">=min")
            margin-bottom: 0

        p
            font-family: fonts.$fontRubrik
            font-weight: 500
            font-size: 1.125em
            line-height: 2em
            color: #262625
            margin: 0

        ul li
            line-height: 2em

        ul
            @include include-media.media("<min")
                margin: 0

    &__block--legal
        @include include-media.media(">=min")
            display: none

    &__block--social
        p
            padding-bottom: 5px

            @include include-media.media(">=min")
                padding-bottom: 5px

        a
            display: inline-block
            margin: 0 9px 0 0
            width: 16px
            height: 19px
            text-decoration: none
            background-size: auto 22px
            background-position: center center
            background-repeat: no-repeat

    a
        color: #686868
        text-decoration: none

    a:hover
        color: $footerHoverColor

    &__linkText
        display: block
        line-height: 0.7em

        &:first-of-type
            line-height: inherit
