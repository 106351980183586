@use "../library/variables"


.socials
    list-style: none
    display: flex
    margin: 8px 0 0 0
    padding: 0

    &__item
        margin-left: auto

    &__icon
        margin-left: 10px
        display: inline-block
        width: 14px
        height: 12px
        text-decoration: none
        background-size: auto 16px
        background-position: center center
        background-repeat: no-repeat

.socials
    &-instagram
        background-image: url(variables.$img-path + "/instagram-icon-v2.png")
        &:hover
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHzSURBVHgB7VXdbcIwEHZC4bXpBgk/Em/NBpAN2gnoBoUJkkxQmKDpBLQTkE5AeEPiR96g6SsC0u+CI5kocaDPnHSyc7a/O3++uzB2kwrRioztdrufJMkA2senydQSaZoW6bo+Wa1WUaWDZrPpYvCgMZTTYQW4QSoCIfG2261f6qDT6diHw2FOUR2PR4dzHrMLxITgBlNMbYzOer0OszVd3gjwVxobjcbzJeAEjBvPATqggMhG1Mp77nJnbFASLpdLngMyarXaE6Y9AMTYs9hsNkG9XjcQlE17EJAPZ5FEV7EDRPKVjxIRznDQzGyYp28Fpw7mzn6/j4R9AednN9BzDhjAfnLfM3bKJB/OH0gxH5Ftt9vNCFxF5x1TSKvVehGRj5AdY2lpjBtQBrmCuqAMQ2cKAXjKL6IO8muwZQ57KowqBwarEOz5Zf91AP5DMQ4L1tLHrChE9RsgBT8BRJXtgvM4owo2qhcPyildVRjKG1B2iALi0DfKMJFlHtlQkM4ZmK7fs4ob8OxhJSccgyUyigqRQL7pdijIfHrSm5VTZllWACqSbrdrsitFtI0EgbzL9jOKkNMBjSig6TVOpGZHNE3ktaJ27WFw00X0JXZq26WVKijNaPVRkJ7SAQm1bbSAIRw8SofLhP4ZIfRDbtM3uVj+AGba66MmoRcEAAAAAElFTkSuQmCC')
    &-twitter
        background-image: url(variables.$img-path + "/x-icon-v2.png")
        &:hover
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI0SURBVHgB7ZQ/bNpQEMaNDcgbLkIiWw0IqaPZOpatI9mSqWTrVhg7BbaO6dZOkLET6lQxkU7tFrpRIYHHVkLgbhV/+7voIbmWsZMsWXKS9e58z9935+/e07RHe2hL+INisXjB8iawx0un05XRaOSGAZRKpdput+upsD2ZTFr+vO4PttutJIc3zInEFU8X11oulz3btq0gOO9swDsqfB8EFzP+K9Xz/mYymT7AdUITwmP83/h1VnOxWPT94LquD3CPpBjAT7UQM4IvIPGy2ewNKADP+LBpWVYBkNe8dyH5Id2Q+yY8PC6FVKW4MIKEdsB8ejQB6AJ4jW+JHvLL8B0BJ64e0ieSQJEIqAN4lQ68zWYzUCnRI1L8velRSdFAgFg7hmF4+G0FrkF6HAceS+BiAoRrr1arDnpcMDWXitzRbmFG3Ib5fO4+wXBPWCwI3vK7Tohf5vP5T7PZzNPu28HeAHyq3EYymXRkaiRA7EHY+bgTAUKfU3UNVyqVkeyZpikp0UPOwnnU90YcOEtLdXGK0B8hazBNL9DjTP26Bssfzsf3OxEALmfgnQrljvmAHr8EjLguetBNe69HLpfrSz6IE3oOyuWyQ5XXPvCWP18oFLoAv5IrhX1f1SGUca4weF5kB3LHsHzhkYm5nE6njeAero4rVXkN8J9yT+E/xz/iV30+2IHvAhOSIZVXtAOmupS9wSlqynnZB0l/JpVKSdVn4q/X66EWYePxeAhJlf2RY/posfYPxx4GlVkDITQAAAAASUVORK5CYII=')

    &-facebook
        background-image: url(variables.$img-path + "/facebook-icon-v2.png")
        &:hover
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGwSURBVHgB7VXLbcIwGHaciHPEBOEl5VY2KGzABrBBxQZ0g25AmKBlAuipV3pD4lEfewsLQPr9yFFtx04IZz4psv3b/v63w9gDFfCqDnS73UGWZWN8AywjKd54nicwLg6Hw8ddCiLA9/25JC6DaDQaw+12K2ybvk3Y6/X6GL7wxawa4fl8njSbzd80Tb/NzYIHZDnnfMX+w6Ff8Lw1vCKiE+ZP0sOQ1vB4uNvtNur5wCQA+buLHHhFzGeqoN1u/0gF5AndbWl86oISiqHvIBfH43HGyhF1Op2JKtA8uFwu45LLy3xCOYK1K2m5CeJI8gU3Nl3WE075BOQjBzlDTjSOOgpuRRjHcZQvtCpCwjLzNJI+3O/3axsTCi7EfmrK0RetvC9MDwSrgSAIrB6rTaclGfH7RG1HqgyJH6EyqDc2eY1TkiGn79kkpz5R15oHaJSEFfECxXOZ2CtoTjJMJ5bzC6cCirVpQU0INGLiVHAVcD5lSknWAYybFvhMAcXZdvAGTG1PN7edJDeRQHpTBKuGoFLGM/Jm2wyctwAMLfm20A9H25ev6hKGJCC/K6QPXPEHzMiypNUbmvIAAAAASUVORK5CYII=')

    &-linkedIn
        background-image: url(variables.$img-path + "/linkedin-icon-v2.png")
        &:hover
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD2SURBVHgB7VPRDYIwEG2L4Rs3qMAAjoAb6AS4gSOgG8gE4gaOgBvwDyQdgQUAX5OaNIVEBRL84CWX6z2O90qPErJgblCT8DwvbNv2imVlWdYhz/OMjAAzCYifkRwEr+v6RkaC9XAOmRAdAxzLDimjlKa2bR/Iv6MzZNd1I+yeq/JZlmXi+36A2YSSQK6apokZYyeUe4SDfvkj3GWvqbfqOFIqxQK1lilBzRFH1SIgLoX5+x3VH2AjoiiKVNfrG/IncKKJ64BRZHJDDOSXPXBMa8QGZaYZbCcxwP24CCEqhIBZrD1yJjGA7te3e5DBL1gM5jdYMD9eLYNZ8A7NIl8AAAAASUVORK5CYII=')

    &-threads
        background-image: url(variables.$img-path + "/threads-icon-v2.png")
        &:hover
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH4SURBVHgB7VXLdcJADFyM4RrSAd87dGAqSFJBTAWYCmIqCKkApwKgAqACfOTAZzvAucIDZwbWecaY2CRX9J6i9a6ikWZXQoi7JEgmyaFSqZgwT77v12GLatvNZDIu7Gi5XA7/BFCEaJo2wJKBPQSc0AbHADSOAbCfy+Va8/lcpgZQwcdYFqDdw+HgSCm9GB8TyzeohE8j6nNVQMugXC77tVqtnsLXpC/0XaSpgEH3+/2Mma9WKzuUbRt0HAFBj5vP5z8CWkqlkoOzV1TxGK1CiwIguEmLAE4oOOmyELhA5Xq73Y5xxLXQdb0H42Wz2WeRouQxyp2F9/BtV6tVQ1zSYifFu6AI/7RBltP1en2WDbNFhoY4XbwHH3LugcbGbwB6zF4BlGwioG0YW9ETFo/VoBcccUU0kSAIwEp6bCzoC8CbVOxJJgPQPnz64oYKzl5B0FDRZkJVPBsBVMK2ATKNqySuAqnGws83/+x2OyMUnM3F1zXEHVhMCk809gXFAUyh9eAJsosVaJ8PgIpvG+oGGZM+gD2kAmBWylrH9NE4ACHnXXUPE6w74dfDiuHzJdKK6oVUo4J00VdN3QtJHHbIuBN3eaQQPrwLi1XBp5kaIAJSFKdxzfkvg2P1EJjAEOOldW2S3vKDY4hTF1Mks4Z+LhaLibjLf+QbW+L5p5x/iwoAAAAASUVORK5CYII=')

    &-bluesky
        background-image: url(variables.$img-path + "/bluesky-icon.png")
        &:hover
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF0SURBVHgB7VPLbYNAEB1w4EwJhI/EkVSQuAOnA7uCKBUYV2BSQewKklRgSiA3JD7adJAGgLyVIMKwu/jog5+0gt2ZeW95MxDdcFVwHGeP1bquewqCwL60zvf9kNfwWs4xjGkjgXZUG1VVtSP1pV7wiIdnqPnn1UmNCARbBfl2TD7GWIDRhSLdWSTIZyqBXxIj8jzvqd/A75WEfMJxN9xomsbatg1FVU3TfNi2fY9XCzl7kgAccgEU/pAc1mKxeEcOJ7BlSYh/D/dnFoHgQAqgmFuzVuWYphlLBfI8T/GJzyRu9hyYruvLLMvY8FATZcJrC0Ix1iO2Vrdk4Ja9YfYjUVATkNu4yYkUPkuQYhCWjLGzJk9+NJCvFeSM5KMcooerCZ9AICEJYFmCRn/J4nVdp7MCRVEkIPokscBRNmmIHWDPvEB3kw2NJonfnovzJYqh5lXEJRTgjULDHmDHsScwDGPTx/EVfJTTTmhXluWkuTdcD/4A+tqNy3S6b3cAAAAASUVORK5CYII=')
