@use "socials"

@use "../library/breakpoints"
@use "../library/colors"

@use "btnContribution"
@use "userAccountWidget"

@use "hamburgerNav"
@use "include-media/dist/include-media"
@use "../library/fonts"
@use "topNav"

.mainNav
    display: none

.topNav
    display: none

@include include-media.media(">=min")
    .topNav
        display: flex

    .mainNav
        display: block
        font-family: fonts.$fontRubrik
        margin: 0
        padding: 0
        background-color: white

        &__header
            display: flex
            justify-content: space-between
            max-width: breakpoints.$page-full-width
            min-width: breakpoints.$page-min-width
            width: 100%
            height: 91px
            margin: auto
            padding-right: 5px

            @include include-media.media(">small")
                height: 101px

            &__item
                display: flex
                &:last-child
                    align-items: center

        &__logo
            margin-bottom: 1px
            height: 90px
            width: 90px
            aspect-ratio: 1
            object-fit: cover
            @include include-media.media(">small")
                height: 100px
                width: 100px

        &__title
            display: inline-block

        &__date
            position: relative
            align-self: flex-end
            font-size: 0.8125rem
            line-height: 1
            color: #555
            bottom: 25px
            left: 25px

            @include include-media.media(">small")
                font-size: 0.875rem

        &__social
            font-size: 1rem
            line-height: 1
            position: relative
            margin-left: auto
            bottom: -4px

            &__title
                font-size: 0.6875rem
                line-height: 1
                color: #686868
                text-align: right

                @include include-media.media(">small")
                    font-size: 0.75rem

            &_icon
                filter: brightness(60%)
                &:hover
                    filter: brightness(100%)

        &__contribution
            margin-left: 1.625rem

        &__sections,
        &__subSections
            @include include-media.media(">=full")
                max-width: breakpoints.$page-full-width
            font-family: fonts.$fontVerlag
            width: 100%
            padding: 0
            position: relative
            list-style: none
            margin: 0 auto
            display: flex

        &__subSections
            column-gap: 0.6vw
            word-spacing: -2px
            @include include-media.media(">small")
                word-spacing: -1px
                column-gap: 0.9vw
            @include include-media.media(">=full")
                word-spacing: 0
                column-gap: 18px

        &__sectionLink,
        &__subSectionLink
            text-decoration: none
            display: block
            color: white
            font-weight: 400
            border: 0
            word-break: keep-all

        &__section
            background-color: #000
            border-bottom: 1px solid white

        &__sectionItem
            margin: 0 18px 0 0
            @include include-media.media(">small")
                margin: 0 26px 0 0

            &:nth-of-type(2)
                padding-left: 5px

        &__sectionLink
            padding: 14px 0
            position: relative
            text-transform: uppercase
            letter-spacing: 0.1em
            line-height: 32px

            font-weight: bold
            font-size: 0.625em
            @include include-media.media(">small")
                font-weight: 400
                font-size: 0.75em

        &__sectionLink--withBar
            &::after
                display: none
                content: ''
                width: 100%
                position: absolute
                left: 0
                bottom: 10px
                border-width: 0 0 8px
                border-style: solid
            &:hover
                &::after
                    display: block

        &__sectionLink--active
            &::after
                display: block

        @each $name, $color in colors.$navBorder
            &__sectionLink--#{$name}
                &::after
                    color: #{$color}

        &__subSections__item
            padding-left: 5px
            margin: 0
            min-width: fit-content

        &__subSectionLink
            font-family: fonts.$fontRubrik
            padding: 7px 0
            font-size: 12px
            @include include-media.media(">small")
                font-size: 13px
            letter-spacing: 0.05em
            font-weight: 500
            &:hover,
            &--active
                text-decoration: underline
        @each $name, $color in colors.$navBorder
            &__subSection--#{$name}
                background-color: #{$color}

        &__logoItem
            display: none

        &__LogoLink
            padding: 0
            width: 60px
            height: 60px

        &__section__logo
            height: 100%
            width: 100%
            margin-left: 0
            aspect-ratio: 1
            object-fit: cover

        @media screen and (max-width: (breakpoints.$page-full-width + 20px))
            .mainHeader--sticky
                &__section,
                &__subSection
                    padding-left: 0

        &__serviceItem
            position: relative
            padding-right: 30px
            cursor: pointer
            user-select: none
            &::after
                content: '>'
                position: absolute
                display: inline-block
                top: 18px
                right: 12px
                color: #aeaeae
                font-size: 18px
                font-weight: 300
                transform: rotate(90deg)
            span
                color: #aeaeae
            &:hover
                span
                    color: white
                &::after
                    color: white

        &__serviceItem--state-open
            span
                color: white
            .servicesMenu
                display: flex
            &::after
                transform: rotate(-90deg)
                color: white

        // ------------------------------------------
        // SEARCH
        // ------------------------------------------

        &__searchItem
            position: absolute
            z-index: 999
            top: 50%
            transform: translateY(-50%)
            padding: 0
            height: 36px
            width: 36px
            cursor: pointer
            margin: 0
            right: 35px

            @include include-media.media(">=full")
                right: 40px

            &:hover
                background-color: #414141
                .mainNav__searchLink
                    background-image: url("assets/recherche-white.inline.svg")

        &__searchLink
            width: 100%
            height: 100%
            background-size: auto 15px
            background-position: center center
            background-repeat: no-repeat
            background-image: url("assets/recherche-grey.inline.svg")

        &__searchMenu
            display: none
            position: absolute
            background-color: rgba(0, 0, 0, 0.85)
            color: white
            top: 48px
            right: 0
            border: 1px solid white
            &::after
                content: ''
                width: 8px
                height: 8px
                background-color: black
                position: absolute
                top: -5px
                right: 14px
                transform: rotate(45deg)
                z-index: 998
                border-top: 1px solid white
                border-left: 1px solid white

        &__search__btnSubmit
            cursor: pointer
            content: ''
            position: absolute
            right: 6px
            top: 6px
            width: 60px
            height: 46px
            background-color: transparent
            border: 0
            background-size: auto 17px
            background-position: center center
            background-repeat: no-repeat
            background-image: url("assets/recherche-grey.inline.svg")
            z-index: 1000

        &__search__input
            color: white
            border: 0
            outline: none
            background-color: transparent
            padding: 20px 25px
            width: 700px
            font-size: 14px
            letter-spacing: 0.03em
            &::placeholder
                color: #aeaeae

        &__searchItem--state-open &__searchLink,
        &__searchItem--state-open:hover &__searchLink
            background-image: url("assets/ico_close_b.inline.svg")

        &__searchItem--state-open &__searchMenu
            display: block

        &__searchMenu--valid
            &__search__btnSubmit
                background-image: url("assets/recherche-white.inline.svg")
            &:hover
                background-color: #353535


        &__userAccountItem
            position: absolute
            z-index: 999
            top: 50%
            transform: translateY(-50%)
            padding: 0
            height: 36px
            width: 36px
            cursor: pointer
            margin: 0
            right: 0

            &:hover
                background-color: #414141

            &--state-open:hover
                background-color: transparent

            &--state-hidden
                display: none

        &__userAccountItem--state-open .userAccountWidget__popin
            display: block

    .mainHeader--sticky
        .topNav
            display: none

        .mainNav
            position: fixed
            top: 0
            transition: top .2s ease-in-out
            right: 0
            width: 100%
            z-index: 200

            &__header
                display: none

            &__logoItem
                display: list-item

            &__sectionItem
                margin: 0 13px 0 0

                @include include-media.media(">small")
                    margin: 0 20px 0 0

                &:last-child,
                &.mainNav__searchItem
                    margin-right: 0

                &:nth-of-type(1)
                    @include include-media.media(">small")
                        margin: 0 15px 0 0
    // ------------------------------------------
    // SERVICES
    // ------------------------------------------

    .servicesMenu
        cursor: default
        position: absolute
        display: none
        background-color: black
        color: white
        top: 60px
        right: -80px

        @media screen and (max-width: (breakpoints.$page-full-width + 20px))
            right: 0

        z-index: 999
        border: 1px solid white
        &::after
            content: ''
            width: 8px
            height: 8px
            background-color: black
            position: absolute
            top: -5px
            right: 135px

            @media screen and (max-width: (breakpoints.$page-full-width + 20px))
                right: 50px

            transform: rotate(45deg)
            z-index: 998
            border-top: 1px solid white
            border-left: 1px solid white

        &__Item
            font-family: fonts.$fontRubrik
            text-transform: none
            display: block
            max-width: none
            min-width: auto
            max-height: none
            min-height: auto
            width: 100%
            white-space: nowrap
            margin: 0
            padding: 0
            &:first-child
                letter-spacing: 0.03em
                margin-bottom: 20px
                font-weight: 400
                font-size: 14px

        &__link
            color: #CCC
            letter-spacing: 0.04em
            text-decoration: none
            font-size: 12px !important
            line-height: 26px !important
            padding: 0
            margin: 0
            &:hover
                text-decoration: underline

        &_services,
        &_detente,
        &_contact
            display: block
            max-width: none
            min-width: auto
            max-height: none
            min-height: auto
            margin: 35px 30px
            padding: 0
