@use "../library/colors"

.topNav
  height: 40px
  display: flex
  justify-content: center
  align-items: center
  background-color: white
  border-bottom: solid 1px #f0f0f0

  &__link
    text-decoration: none
    font-family: Rubrik
    font-size: 13px
    font-weight: 500
    font-stretch: normal
    font-style: normal
    line-height: normal
    letter-spacing: normal
    color: #2d2d2d
    padding: 0 20px 0 20px

    &--current
      color: #a2a2a2
      pointer-events: none

    &:hover
      color: colors.$DEF-2

.lineBorder
  border-left: solid 1px #f0f0f0
